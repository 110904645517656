<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="1000px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          Repair Form<br />
          <v-row style="margin-top: 4px"
            ><br />
            <v-col cols="12" sm="2" md="2">
              <v-autocomplete
                :items="wells"
                v-model="editedItem.well_id"
                item-value="well_id"
                item-text="wellname"
                label="Well"
                dense
                :readonly="editedItem.id > 0"
                @change="change_well"
                :rules="[(v) => !!v || 'Well required']"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-select
                :items="user_list"
                v-model="editedItem.operator_id"
                item-value="id"
                :readonly="status == 6 || status == 7"
                item-text="name"
                label="Repair by"
                :rules="[(v) => !!v || 'Repaired by required']"
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <datepicker
                label="Repair Date"
                :edit="true"
                v-model="editedItem.date_reparation"
                :rules="[(v) => !!v || 'Repair Date required']"
                :clearable="false"
                :key="cs"
              ></datepicker>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-select
                :items="user_list"
                v-model="editedItem.interpreted_by"
                :readonly="status == 6 || status == 7"
                item-value="id"
                item-text="name"
                label="Evaluation by"
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <datepicker
                label="Evaluation Date"
                :edit="true"
                v-model="editedItem.interpreted_at"
                :readonly="status == 6 || status == 7"
                :clearable="false"
                :key="cs"
              ></datepicker>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="selected_tab" :key="tkey">
            <v-tab :key="item.id" v-for="item in list_details">
              {{ item.component }}
            </v-tab>
            <v-spacer></v-spacer>
            <v-tab key="-1"> Attached files </v-tab>
            <v-tab-item :key="item.id" v-for="item in list_details">
              <br />
              <h3>Failures:</h3>
              <br />
              <v-row
                class="rowh"
                :key="failure.id"
                v-for="failure in failures.filter(
                  (x) => x.cmp_code == item.cmp_code
                )"
                ><h4>-{{ failure.issue }}</h4></v-row
              ><br />
              <h3>Comment:</h3>
              <v-row>
                <v-col cols="8" sm="8" md="8" dense>
                  <v-textarea
                    align-self="center"
                    hide-spin-buttons
                    autocomplete="off"
                    v-model="item.comment"
                    :readonly="status == 6 || status == 7"
                    outlined
                    dense
                    rows="2"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="2" md="2" dense>
                  <v-checkbox
                    v-model="item.repared"
                    label="Repared"
                    :readonly="status == 7"
                    v-if="status == 6 || status == 7"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="12" sm="3" md="3" dense>
                  <v-checkbox
                    v-model="item.with_test"
                    label="Required Test"
                    :readonly="status == 7"
                    v-if="status == 6 || status == 7"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <br />
              <filelist
                v-if="list_details.length > 0"
                :item="editedItem"
                :editer="true"
                :doc_type="1"
                :isform="false"
                :auth="'01007'"
                :well_id="parseInt(editedItem.well_id)"
              />
            </v-tab-item> </v-tabs
          ><br />
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ma-1"
            color="blue darken-1"
            @click.stop="accept_daialog(true)"
            v-if="status == 6"
          >
            Accept
          </v-btn>
          <v-btn
            class="ma-1"
            color="blue darken-1"
            @click.stop="accept_daialog(false)"
            v-if="status == 6"
          >
            Return
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-1"
            color="blue darken-1"
            @click.stop="save(6)"
            v-if="status <= 5 || status == 8"
          >
            Completed
          </v-btn>
          <v-btn
            class="ma-1"
            color="blue darken-1"
            @click.stop="save(status)"
            v-if="status != 7 && status != 6"
          >
            Save
          </v-btn>
          <v-btn class="ma-1" color="blue darken-1" @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form ref="form" lazy-validation>
      <v-dialog
        v-model="dialog_accept"
        max-width="600px"
        persistent
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-text>
            <br />

            {{ accept ? "Conclusion" : "Justification" }}
            <v-textarea
              ref="comment"
              outlined
              dense
              v-model="accept_comment"
              :rules="[
                (v) =>
                  !!v ||
                  (accept ? 'Conclusion' : 'Justification') + ' required',
              ]"
              variant="outlined"
              auto-grow
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              v-if="accept"
              class="ma-1"
              color="blue darken-1"
              @click.stop="save(7)"
            >
              Accept
            </v-btn>

            <v-btn
              v-if="!accept"
              class="ma-1"
              color="blue darken-1"
              @click.stop="save(8)"
            >
              Return
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click.stop="accept_dialog_close()"
              class="ma-1"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <confirmdialog ref="validation" />
  </v-form>
</template>
<script>
import SaveReparation from "../graphql/Reparation/SaveReparation.gql";
import getElements_list from "../graphql/Reparation/DATA.gql";
export default {
  components: {
    filelist: () => import("./FileList.vue"),
    datepicker: () => import("../components/DatePicker.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    dialog: Boolean,
    item: Object,
    details: Array,
    wells: Array,
    user_list: Array,
    isNotified: { type: Number, default: 0 },
  },
  data() {
    return {
      selected_tab: -1,
      tab: null,
      accept: true,
      dialog_accept: false,
      accept_comment: "",
      list_details: [],
      failures: [],
      barriers: [],
      editedItem: {},
      klist: 2000,
      klist2: 500,
      tkey: 5000,
      selected: false,
      cs: 1000,
      headers: [{ value: "component", selected: true }],
      headers_fail: [{ value: "issue", selected: true }],
      status: 5,
    };
  },
  watch: {},
  mounted() {
    if (this.dialog) {
      this.selected_tab = 0;
      this.status = 5;

      this.editedItem = Object.assign({}, this.item);
      this.list_details = Object.assign([], this.details);
      this.getdetails(this.editedItem.well_id);
      if (this.editedItem.id > 0) {
        this.status = this.editedItem.statut_id;
      } //else if (this.isNotified == 1) {
      //     this.getdetails(this.editedItem.well_id);
      //   }
      this.cs++;
    }
  },
  computed: {
    today() {
      return this.$store.state.today;
    },
    users_exe() {
      let l = [];
      l = this.user_list.filter((elm) => elm.autorisations.includes("06003"));
      return l;
    },
    users_eval() {
      let l = [];
      l = this.user_list.filter((elm) => elm.autorisations.includes("07003"));
      return l;
    },
  },
  methods: {
    add_new_elements(elements) {
      if (elements.length > 0)
        if (!this.editedItem.details) this.editedItem.details = [];
      elements.forEach((x) => {
        if (this.editedItem.details.findIndex((y) => y.cmp_id == x.id) < 0)
          this.editedItem.details.push({ cmp_id: x.id, cmp_code: x.cmp_code });
      });
      this.tkey++;
    },
    change_well(item) {
      if (item) {
        this.getdetails(item);
      }
    },
    closeDialog() {
      this.$emit("close_reparation");
    },
    row_selected() {},
    async getdetails(id) {
      let r = await this.$apollo.query({
        query: getElements_list,
        variables: { Well_id: parseInt(id) },
        fetchPolicy: "no-cache",
      });
      if (r) {
        this.failures = r.data.getfailures_list;
        this.barriers = r.data.wmf_list;
        if (this.status < 6 || this.status == 8) {
          this.list_details = r.data.getElements_list;
          this.add_new_elements(this.list_details);
        }
        this.selected_tab = 0;
        this.tkey++;
      }
    },
    accept_dialog_close() {
      this.dialog_accept = false;
    },
    accept_daialog(value) {
      this.accept = value;
      this.dialog_accept = true;
    },
    recalc_failures() {
      let wel_failures = [];
      this.failures.forEach((x) => {
        if (
          x.failure_id > 0 &&
          x.statut_id < 2 &&
          this.list_details.findIndex(
            (y) => y.cmp_code == x.cmp_code && y.repared == true
          ) < 0
        )
          wel_failures.push(x);
      });
      let failure = { cmp_code: "-1" };
      let multiples = this.$repaire_failure(wel_failures, this.barriers);

      // update multiples
      if (multiples.length > 0) {
        failure = multiples.reduce(function (prev, current) {
          if (+current.failure_taux < +prev.failure_taux) {
            return current;
          } else {
            return prev;
          }
        });
      }
      return failure;
    },
    async save(status_id) {
      if (this.$refs.form.validate()) {
        var min = this.failures.reduce(function (prev, current) {
          if (+current.due_date < +prev.due_date) {
            return current;
          } else {
            return prev;
          }
        });
        let failure = null;
        let duedate = min.repair_date;
        if (status_id == 7) failure = await this.recalc_failures();
        let dt = [];
        let r = {
          id: this.editedItem.id,
          well_id: this.editedItem.well_id,
          date_reparation: this.editedItem.date_reparation,
          due_date: duedate,
          operator_id: this.editedItem.operator_id,
          interpreted_at: this.editedItem.interpreted_at,
          interpreted_by: this.editedItem.interpreted_by,
          statut_id: status_id,
        };
        this.editedItem.details.forEach((x) => {
          let comment = x.comment;
          let repared = x.repared;
          let with_test = x.with_test;
          //  if (this.status < 6 || this.status == 8)
          {
            let k = this.list_details.findIndex((y) => y.id == x.cmp_id);
            if (k >= 0) {
              comment = this.list_details[k].comment;
              repared = this.list_details[k].repared;
            }
          }
          if (this.editedItem.id < 0)
            dt.push({
              comment: comment,
              cmp_id: x.id,
              with_test: with_test,
              repared: repared,
            });
          else
            dt.push({
              id: x.id,
              comment: comment,
              cmp_id: x.cmp_id,
              with_test: x.with_test,
              repared: repared,
            });
        });
        this.$apollo
          .query({
            query: SaveReparation,
            variables: {
              reparation: r,
              details: dt,
              statut_id: status_id,
              failures: failure,
              // comment: null,
              user_id: parseInt(this.$store.state.me.id),
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            this.editedItem = data.data.SaveRepair;
            // this.list_details = this.editedItem.details;
            if (this.editedItem.id > 0) this.status = this.editedItem.statut_id;
            this.dialog_accept = false;
            this.$store.state.changed = true;
            this.$emit("refresh_reparation");
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style scoped>
.rowh {
  margin-left: 12px;
  height: 30px !important;
}
</style>
